.step-warning svg {
    color: #FFC107 !important
}


.border {
    border-bottom: 1px solid black;
    width: 100%;
}

.content {
    padding: 0 10px 0 10px;
}



.swal2-container {
    z-index: 10000 !important;
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%) !important;
}

.MuiModal-root {
    z-index: 9999 !important;
}

.MuiOutlinedInput-input {
    text-transform: uppercase;
}



.css-1fvpgpf {
    background: transparent !important;
}


.SnackbarContainer-top {
    z-index: 99999 !important;
}

.public-DraftStyleDefault-block {
    margin: unset !important;
}


.public-DraftStyleDefault-block {
    margin: unset !important;
}

.rdw-editor-wrapper {
    border: 1px solid #F1F1F1;
}

.rdw-editor-toolbar {
    border: unset !important;
    border-bottom: 1px solid #F1F1F1 !important;
    border-radius: 5px;
}

.ListApproved .rdw-editor-wrapper, .ListReview .rdw-editor-wrapper {
    border: none !important;
}

.ComemtsCustom .rdw-editor-wrapper, .ListReview .rdw-editor-wrapper {
    border: 1px solid #F1F1F1 !important;
}





.btn--shockwave.is-active {
    -webkit-animation: shockwaveJump 1s ease-out infinite;
    animation: shockwaveJump 1s ease-out infinite;
}

.btn--shockwave.is-active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    -webkit-animation: shockwave 1s .65s ease-out infinite;
    animation: shockwave 1s .65s ease-out infinite;
}

.btn--shockwave.is-active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    -webkit-animation: shockwave 1s .5s ease-out infinite;
    animation: shockwave 1s .5s ease-out infinite;
}

@-webkit-keyframes shockwaveJump {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
    }

    50% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }

    55% {
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    60% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes shockwaveJump {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    40% {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
    }

    50% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }

    55% {
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    60% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes shockwave {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
    }

    95% {
        box-shadow: 0 0 50px transparent, inset 0 0 30px transparent;
    }

    100% {
        -webkit-transform: scale(2.25);
        transform: scale(2.25);
    }
}

@keyframes shockwave {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
    }

    95% {
        box-shadow: 0 0 50px transparent, inset 0 0 30px transparent;
    }

    100% {
        -webkit-transform: scale(2.25);
        transform: scale(2.25);
    }
}

.btn--jump.is-active {
    -webkit-animation: .4s jump ease infinite alternate;
    animation: .4s jump ease infinite alternate;
}

@-webkit-keyframes jump {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    }

    100% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
}

@keyframes jump {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    }

    100% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
}

.MuiOutlinedInput-input {
    font-weight: 800 !important;
}

.imgActionNofification img {
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.imgActionNofification img:hover {
    box-shadow: 0 1px 9px 0 rgb(0 0 0 / 39%);
}

.iconNotificationCustom {
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.5s ease;
    opacity: 0.8;
}

.iconNotificationCustom:hover {
    /* box-shadow: 0 1px 9px 0 rgb(0 0 0 / 39%);*/
    /*    transform: translate(3px);*/
    opacity: 1;
}



.MuiTooltip-popper {
    z-index: 9999 !important;
}

.colorNuvei {
    color: #786CD3;
}

.formnuvei{
    cursor: pointer !important;
}


.formnuvei:hover, .formnuveiActive {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); 
    transform: translateY(-2px); 
    border-radius:5px;
    padding: 5px;
}

.banknuvei{
    
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1);
}



@keyframes glow {
    0% {
      box-shadow: 0 0 5px #66bb6a, 0 0 10px #66bb6a, 0 0 15px #66bb6a, 0 0 20px #66bb6a;
    }
    50% {
      box-shadow: 0 0 20px #66bb6a, 0 0 30px #66bb6a, 0 0 40px #66bb6a, 0 0 50px #66bb6a;
    }
    100% {
      box-shadow: 0 0 5px #66bb6a, 0 0 10px #66bb6a, 0 0 15px #66bb6a, 0 0 20px #66bb6a;
    }
  }
  
  .glowing-button {
    animation: glow 1.5s infinite alternate;
  }